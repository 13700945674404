import type { ReactionSubmitPayload } from '@snapchat/mw-common';
import { VideoTimeFormat } from '@snapchat/mw-common';
import type { FC, MutableRefObject } from 'react';
import { useCallback, useContext } from 'react';

import { AppContext } from '../../../../AppContext';
import { UrlParameter } from '../../../../constants/urlParameters';
import { logValue } from '../../../../helpers/logging';
import { getBitmojiUrl } from '../BitmojiCanvas/constants';
import { BitmojiContext } from '../BitmojiProvider';
import { bitmojiReactionCss } from './BitmojiControls.styles';

interface ReactionButtonProps {
  analyticsId: string;
  reactionId: string;
  avatarId: string;
  videoTimestampRef: MutableRefObject<number | undefined>;
  sendToBrs?: (reactionRequest: ReactionSubmitPayload) => void;
  videoId?: string;
  isDisabled: boolean;
}

const bucketDurationSeconds = 5;

// The estimated amount of time the test video is behind the current utc timestamp
const videoTimestampOffset = 25221000;

const getRandomBuffer = () => {
  const plusOrMinus = Math.random() > 0.5 ? -1 : 1;
  const bufferValue = Math.floor(Math.random() * 6);

  return bufferValue * plusOrMinus * 1000;
};

export const ReactionButton: FC<ReactionButtonProps> = ({
  analyticsId,
  reactionId,
  avatarId,
  videoTimestampRef,
  sendToBrs,
  videoId,
  isDisabled,
}) => {
  const { sendToBitmojiStream } = useContext(BitmojiContext);
  const { getCurrentUrl } = useContext(AppContext);
  const url = new URL(getCurrentUrl());

  const requireLiveVideoParam =
    url.searchParams.get(UrlParameter.EXPERIENCE_REQUIRE_LIVE_VIDEO_FOR_BITMOJI) ?? 'true';

  const requireLiveVideoForBitmoji = requireLiveVideoParam === 'true';

  const onBitmojiClick = useCallback(
    (reactionID: string, userAvatarID: string) => {
      let videoTime = videoTimestampRef.current;

      if (!videoTime && !requireLiveVideoForBitmoji) {
        const buffer = getRandomBuffer();
        videoTime = Date.now() - videoTimestampOffset + buffer;
      }

      if (!sendToBitmojiStream || videoTime === undefined) return;

      // Renders the reaction locally.
      sendToBitmojiStream(reactionID, userAvatarID);

      const roundedToNearestSecond = Math.floor(videoTime / 1000);
      const bucketOffset = roundedToNearestSecond % bucketDurationSeconds;
      const bucketTimestamp = roundedToNearestSecond - bucketOffset;

      logValue({
        eventCategory: 'ReactionButton',
        eventLabel: `${analyticsId}:${reactionID}`,
        eventVariable: 'bitmoji_reaction',
        // NOTE: value is the point in time in the video, not elapsed watch time.
        //       value is the UTC epoch value, truncated to bucket sized second increments.
        eventValue: bucketTimestamp,
      });

      const reactionVideoId = `${videoId}`;
      const reactionRequest: ReactionSubmitPayload = {
        videoId: reactionVideoId,

        videoTimeFormat: VideoTimeFormat.UTC_MILISECONDS,
        videoTime,
        bitmojiId: userAvatarID,
        bitmojiReactionType: reactionID,
        reactionTimestamp: new Date().toISOString(),
      };

      sendToBrs?.(reactionRequest);
    },
    [
      analyticsId,
      sendToBitmojiStream,
      requireLiveVideoForBitmoji,
      sendToBrs,
      videoId,
      videoTimestampRef,
    ]
  );

  return (
    <button
      key={reactionId}
      disabled={isDisabled && requireLiveVideoForBitmoji}
      className={bitmojiReactionCss}
      onClick={() => onBitmojiClick(reactionId, avatarId)}
      data-test-id={`bitmoji-reaction-button-${reactionId}`}
    >
      <img src={getBitmojiUrl(reactionId, avatarId)} />
    </button>
  );
};
