import { setLocalStorageItem } from '@snapchat/mw-common';
import type { FormBody } from '@snapchat/snap-design-system-marketing';
import pick from 'lodash/pick';
import { type FC, type ReactNode, useEffect, useState } from 'react';

import type { AnalyticsDataProps } from '../../../../components/Analytics/types';
import type { ContentfulFormRow } from '../../../../components/Form';
import { Form } from '../../../../components/Form/Form';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import { renderRichTextMarkingsOnly } from '../../../../utils/renderText/renderRichText';
import { RegistrationDestination } from '../../types';
import { getRegistrationLocalStorageData } from '../../utils/getRegistrationLocalStorageData';
import type { CventConnectorDataProps, SalesforceConnectorDataProps } from '../types';
import { errorTextCss } from './RegistrationForm.style';

interface RegistrationFormProps {
  id: string;
  formAnalytics: AnalyticsDataProps;
  formRowsCollection: Items<ContentfulFormRow>;
  rememberRegistration: boolean;
  confirmationUrl: string;
  unknownErrorText: RichText;
  connector: CventConnectorDataProps | SalesforceConnectorDataProps;
  storedFields?: string[];
}

export const RegistrationForm: FC<RegistrationFormProps> = ({
  id,
  formAnalytics,
  formRowsCollection,
  rememberRegistration,
  confirmationUrl,
  unknownErrorText,
  connector,
  storedFields = [],
}) => {
  // State
  const [successfullyRegistered, setSuccessfullyRegistered] = useState<boolean>(false);
  const [formErrorText, setFormErrorText] = useState<ReactNode>();

  // Callback for form submission
  const onFormSubmit = (response: Response, formBody: FormBody) => {
    if (response.ok) {
      // Store any fields that were provided and match what is given in "storedFields"
      // TODO: Test once UI is connected to backend via "CVent/Salesforce Connector"
      const storedFieldsData = pick(formBody, storedFields);

      const localRegistrationData = getRegistrationLocalStorageData();
      const newLocalStorageData = {
        ...localRegistrationData,
        [id]: storedFieldsData,
      };
      setLocalStorageItem('experience-registration', JSON.stringify(newLocalStorageData));
      setSuccessfullyRegistered(true);
      return;
    }

    if (response.status === 401 && connector.__typename === 'CventConnector') {
      const errorText = renderRichTextMarkingsOnly(connector.allowlistErrorText);
      setFormErrorText(errorText);
    } else {
      const errorText = renderRichTextMarkingsOnly(unknownErrorText);
      setFormErrorText(errorText);
    }
  };

  // Check if the user has already registered
  useEffect(() => {
    const localRegistrationData = getRegistrationLocalStorageData();
    const hasRegistered = localRegistrationData[id] !== undefined;
    setSuccessfullyRegistered(rememberRegistration && hasRegistered);
  }, [id, rememberRegistration]);

  // If the user has already registered, redirect them to given confirmation URL
  if (successfullyRegistered) {
    return <Redirect path={`/${confirmationUrl}`} />;
  }

  // Tack on params from the connector so the correct handler is used
  const extraParams = (
    connector.__typename === 'SalesforceConnector'
      ? {
          destination: RegistrationDestination.Salesforce,
          Form_Category__c: connector.category ?? '',
          Year__c: connector.year ?? '',
          emailTemplateId: connector.confirmationEmail?.sys.id ?? '',
        }
      : {
          destination: RegistrationDestination.Cvent,
          eventId: connector.eventId ?? '',
          admissionItemId: connector.admissionItemId ?? '',
          invitationListId: connector.invitationListId ?? '',
        }
  ) as Record<string, string>;

  return (
    <div>
      {formErrorText && <p className={errorTextCss}>{formErrorText}</p>}
      <Form
        endpoint="/api/register"
        submitText="Submit"
        submitSuccessText="Success"
        analytics={formAnalytics}
        rowsCollection={formRowsCollection}
        sys={{ id: `form-${id}` }}
        callback={onFormSubmit}
        extraParams={extraParams}
      />
    </div>
  );
};
