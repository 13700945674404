import { cx } from '@emotion/css';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import { memo, useCallback, useContext, useRef, useState } from 'react';

import { EventPlayer } from '../../../../components/EventPlayer/EventPlayer';
import type { EventMedia, TimestampBehavior } from '../../../../components/EventPlayer/types';
import { useBitmojiReplays } from '../../hooks/useBitmojiReplays';
import { BitmojiCanvas } from '../BitmojiCanvas/BitmojiCanvas';
import { BitmojiControls } from '../BitmojiControls/BitmojiControls';
import type { BitmojiProps } from '../BitmojiControls/types';
import { BitmojiContext } from '../BitmojiProvider';
import { hideOnMobileCss, videoContainerCss } from './VideoFrame.styles';

export interface VideoFrameProps {
  eventMedia: EventMedia;
  bitmojiProps: BitmojiProps;
  videoId?: string;
  analyticsId: string;
  /**
   * Controls whether to display reaction controls, allow video scrubbing, and how to connect to
   * bitmoji stream
   */
  isLiveEvent: boolean;
  /** How player reports back current timestamp of video. Impacts logging of Video Play events. */
  timestampBehavior: TimestampBehavior;
  /** Amount to offset video progress by. */
  progressOffset?: number;
  /** The number of seconds the player should wait while stalled until attempting backup failover */
  bufferStallTimeout?: number;
  onReceiveBookmark?: (bookmarkId: string) => void;
}

/** Composite UI of Bitmoji canvas, Akamai Player, and Login Kit / Bitmoji Interaction buttons */
const InternalVideoFrame: FC<VideoFrameProps> = ({
  eventMedia,
  bitmojiProps,
  isLiveEvent,
  timestampBehavior,
  progressOffset,
  bufferStallTimeout,
  analyticsId,
  onReceiveBookmark,
}) => {
  /* === state management =========================================================== */

  // user has the ability to hide bitmoji UI elements
  const [hideBitmojiUI, setHideBitmojiUI] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const videoTimestamp = useRef<number | undefined>();

  const updateVideoTimestamp = useCallback(
    (timestamp: number) => {
      videoTimestamp.current = timestamp;
      // This may take time to be correct after pause?!
      // Need to work with Brandon
    },
    [videoTimestamp]
  );

  const { updateVideoPaused } = useContext(BitmojiContext);

  const onPlayStateChange = useCallback(
    (isPlaying: boolean) => {
      setIsPlaying(isPlaying);
      updateVideoPaused?.(!isPlaying);
    },
    [updateVideoPaused]
  );

  /* === custom hooks ================================================================ */

  // For VODs (i.e. isLiveEvent === false), we pass in undefined bitmoji props to prevent the
  // hook from firing. We don't want to be pulling reactions from the replay hook
  // for a live event
  const bitmojiReplayProps = !isLiveEvent ? bitmojiProps : undefined;
  useBitmojiReplays(videoTimestamp, bitmojiReplayProps);

  /* === render ===================================================================== */

  // TODO: refactor this.  We shouldn't need to pass this around to multiple elements & components...
  const bitmojiToggleClass = hideBitmojiUI ? 'hide-bitmoji' : 'show-bitmoji';

  const browserFeatures = useContext(BrowserFeaturesContext);
  const isMobile = browserFeatures.getLowEntropyHints().isMobile;

  return (
    <section>
      <div className={cx(videoContainerCss, bitmojiToggleClass)}>
        {/* We don't want any bitmoji interaction on mobile as it produces a bad/cluttered UX. Approach here is to hide
           the canvas & controls both when a mobile device is detected based on browser attributes (captured by isMobile())
           as well as for mobile-like screen sizes (covered by hideOnMobileCss class). The reason we cannot rely just on
           the hideOnMobileCss class is because that will still not trigger for most phones in landscape mode. */}
        {!isMobile && <BitmojiCanvas {...bitmojiProps} className={hideOnMobileCss} />}

        <EventPlayer
          media={eventMedia}
          isLiveEvent={isLiveEvent}
          timestampBehavior={timestampBehavior}
          progressOffset={progressOffset}
          bufferStallTimeout={bufferStallTimeout}
          onProgressUpdated={updateVideoTimestamp}
          onReceiveBookmark={onReceiveBookmark}
          onPlayStateChange={onPlayStateChange}
          analyticsId={analyticsId}
          autoPlayOnRender={!isLiveEvent}
        />
      </div>
      {isLiveEvent && !isMobile && bitmojiProps.enableBitmoji && (
        <div className={cx(hideOnMobileCss)}>
          <BitmojiControls
            hideBitmoji={hideBitmojiUI}
            hideBitmojiClass={bitmojiToggleClass}
            buttonsDisabled={!isPlaying}
            setHideBitmoji={setHideBitmojiUI}
            videoTimestampRef={videoTimestamp}
            bitmojiProps={bitmojiProps}
            analyticsId={analyticsId}
          />
        </div>
      )}
    </section>
  );
};

export const VideoFrame = memo(InternalVideoFrame, (prev, next) => isEqual(prev, next));

VideoFrame.displayName = 'VideoFrame';
