import { useImperativeEffect } from '@snapchat/core-browser';
import type { InputField } from '@snapchat/mw-contentful-schema';
import { BackgroundColor, BlockSplitPanel } from '@snapchat/snap-design-system-marketing';
import cloneDeep from 'lodash/cloneDeep';
import type { FC } from 'react';
import { useContext } from 'react';

import { AppContext } from '../../../../AppContext';
import { Image } from '../../../../components/Image/Image';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { RegistrationForm } from '../RegistrationFormBlock/RegistrationForm';
import { Sps2024Animation } from '../Sps2024Animation';
import {
  animationOverrideCss,
  registrationContainerCss,
  registrationEventContainerCss,
  registrationEventCss,
  registrationFormCss,
} from './Sps2024Registration.styled';
import type { Sps2024RegistrationProps } from './Sps2024RegistrationQuery';

const defaultAnimationLines = ['SNAP', 'PARTNER', 'SUMMIT', '2024'];

export const Sps2024Registration: FC<Sps2024RegistrationProps> = ({
  animationTextLines,
  animationOverrideImage,
  leftSideText,
  leftSideBackgroundColor,
  rightSideBackgroundColor,
  formTitle,
  unknownErrorText,
  formRowsCollection,
  formAnalytics,
  connector,
  confirmationSlug,
  isClosed,
  closedText,
  sys,
}) => {
  const { userLocation } = useContext(AppContext);
  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  useImperativeEffect(() => {
    // If left side background color is black, we must override the header background color to black
    // to ensure the logo + global nav contrast well with the background.
    if (leftSideBackgroundColor === BackgroundColor.Black) {
      setHeaderBackgroundColorOverride?.(BackgroundColor.Black);
    }
  }, [leftSideBackgroundColor, setHeaderBackgroundColorOverride]);

  // Override the default value of the Subscribe to Marketing field in the US only.
  const isInTheUs = userLocation.country === 'US';
  const { formRowsCollection: editableFormRows } = cloneDeep({ formRowsCollection });

  if (isInTheUs) {
    for (const row of editableFormRows?.items ?? []) {
      for (const field of row.fieldsCollection.items) {
        if (field.name === 'Email_Opt_In__c') {
          (field as InputField).initialValue = 'true';
        }
      }
    }
  }

  const leftContent = (
    <div data-testid="registration-left-side" className={registrationEventContainerCss}>
      {animationOverrideImage ? (
        <Image {...animationOverrideImage} />
      ) : (
        <Sps2024Animation
          data-testid="registration-logo"
          textLines={animationTextLines ?? defaultAnimationLines}
          className={animationOverrideCss}
        />
      )}
      <div data-testid="registration-information" className={registrationEventCss}>
        {renderRichText(leftSideText)}
      </div>
    </div>
  );

  const rightContent = (
    <div className={registrationFormCss}>
      {isClosed ? (
        <div className="closed-text">{renderRichText(closedText)}</div>
      ) : (
        <>
          <h5>{formTitle}</h5>
          <RegistrationForm
            id={sys.id}
            formAnalytics={formAnalytics!}
            formRowsCollection={editableFormRows!}
            rememberRegistration={false}
            confirmationUrl={confirmationSlug!.slug}
            unknownErrorText={unknownErrorText!}
            connector={connector!}
            // firstName is the Cvent first name field designation, while First_Name__c and Interests__c are Salesforce fields
            // TODO: Eventually we can potentially get rid of the storedFields prop and just capture all fields in the form
            // to locale storage by default. That will require a privacy review amendment though.
            storedFields={['firstName', 'Interests__c', 'First_Name__c']}
          />
        </>
      )}
    </div>
  );

  return (
    <section data-testid="registration-form" className={registrationContainerCss}>
      <BlockSplitPanel
        contentLeft={leftContent}
        contentRight={rightContent}
        leftBackgroundProps={{
          backgroundColor: leftSideBackgroundColor ?? BackgroundColor.White,
        }}
        rightBackgroundProps={{
          backgroundColor: rightSideBackgroundColor ?? BackgroundColor.Yellow,
        }}
      />
    </section>
  );
};
